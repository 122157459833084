<template>
  <div></div>
</template>

<script>
import { clearLocalStorage } from "../../../helper/localStorage.js";
import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions("auth", ["loginAsPartner"])
  },

  created() {
    clearLocalStorage();

    const config = {
      headers: { Authorization: "Bearer " + this.$route.query.token }
    };

    const payload = { partnerId: this.$route.query.partnerId, config: config };

    this.loginAsPartner(payload)
      .then(result => {
        this.$router.push({ name: "partner-dashboard" });
      })
      .catch(ex => {
        this.$router.push({ name: "page-error-419" });
      });
  }
};
</script>
